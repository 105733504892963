import styled, { css } from 'styled-components'

import AboutBoxOutline from '../../assets/images/inline/about_us_frame.svg'
import AboutBoxOutlineMedium from '../../assets/images/inline/about_us_frame_tablet.svg'
import AboutBoxOutlineMobile from '../../assets/images/inline/about_us_frame_mobile.svg'
import DashesImage from '../../assets/images/inline/dashes.svg'
import DashesStraightImage from '../../assets/images/inline/dashes_straight.svg'
import PlusVector from '../../assets/images/inline/plus.svg'
import AboutImage from '../../assets/images/inline/about_us.svg'

import { Body2 } from '../Typography/Typography.components'

import { MEDIA_QUERIES } from '../../utils/constants'

export const AboutUsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 145px 188px 0px 146px;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    padding: 163px 32px 0px;
  }

  @media (max-width: ${MEDIA_QUERIES.mediumTablet}px) {
    flex-direction: column;
    padding: 163px 92px 0px 32px;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    padding: 134px 0px 0px 26px;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding: 164px 0px 0px 0px;
  }
`

export const AboutUsCircles = styled(AboutImage)`
  max-width: 150%;
  height: auto;

  > * {
    transform-origin: center;
  }

  @media (max-width: ${MEDIA_QUERIES.mediumTablet}px) {
    order: 2;
    align-self: flex-end;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    max-width: 500px;
    width: 100%;
    height: auto;
  }
`

export const AboutContainer = styled.div`
  ${Body2} > span {
    font-weight: 700;
  }

  position: relative;
  max-width: 580px;
  padding: 40px 42px 48px 124px;
  transform-origin: 'right';
  transform: scale(0);

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    max-width: 595px;
    margin: 0 auto;
    padding: 40px 40px 48px 128px;
  }

  @media (max-width: ${MEDIA_QUERIES.mediumTablet}px) {
    order: 1;
    margin-bottom: 80px;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    margin-left: 0;
    margin-right: 71px;
    padding: 32px 32px 32px 104px;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    margin-right: 0px;
    padding: 24px 16px 24px 16px;
    margin-bottom: 106px;
    width: 100%;
    max-width: 370px;
  }
`

const TextBoxOutlineStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: -1;
`

export const TextBoxOutline = styled(AboutBoxOutline)`
  ${TextBoxOutlineStyles}

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    display: none;
  }
`

export const TextBoxOutlineMedium = styled(AboutBoxOutlineMedium)`
  ${TextBoxOutlineStyles}

  display: none;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    display: block;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    display: none;
  }
`

export const TextBoxOutlineMobile = styled(AboutBoxOutlineMobile)`
  ${TextBoxOutlineStyles}

  display: none;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    display: block;
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    justify-content: space-between;
  }
`

export const AboutUsDashes = styled(DashesImage)`
  margin-left: auto;
  margin-right: 1em;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    display: none;
  }
`

export const AboutUsDashesStraight = styled(DashesStraightImage)`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(20px, 25px);

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    display: none;
  }
`

export const Plus = styled(PlusVector)`
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(100%, 100%);

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    transform: scale(0.5) translate(150%, 150%);
  }
`
