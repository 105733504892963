import React, { FC, useState } from 'react'
import { PageProps } from 'gatsby'
import gsap from 'gsap'
import ScrollTrigger from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Header from '../components/Header/Header'
import Hero from '../components/Hero/Hero'
import LoadingScreen from '../components/LoadingScreen/LoadingScreen'
import ThreeScene from '../components/ThreeScene/ThreeScene'
import About from '../components/About/About'
import Collaboration from '../components/Collaboration/Collaboration'
import Footer from '../components/Footer/Footer'
import Services from '../components/Services/Services'
import Main from '../components/Main/Main'
import { setBodyOverflow } from '../utils/helpers'

const IndexPage: FC<PageProps> = () => {
  const [animateHero, setAnimateHero] = useState(false)

  const handleLoadingAnimationEnd = () => {
    setBodyOverflow()
    setAnimateHero(true)
  }

  return (
    <Layout>
      <Seo
        title="Medical Logistics Innovation Hub"
        description="The place to find the most exciting trends in medical technology, and to share all your innovative ideas. Whether you’re interested in flying drones for medical transportation, technology to draw blood needle-free or you have other ideas to disrupt the medical industry - we’d love to chat about it!"
        lang="en"
      />
      {!animateHero && (
        <LoadingScreen handleLoadingAnimationEnd={handleLoadingAnimationEnd} />
      )}
      <ThreeScene animateHero={animateHero} />
      <Main>
        <Header animateHero={animateHero} />
        <Hero animateHero={animateHero} />
        <About />
        <Services />
        <Collaboration />
        <Footer />
      </Main>
    </Layout>
  )
}

export default IndexPage
