import React from 'react'

const LoadingBar = () => (
  <svg
    width="1194"
    height="80"
    viewBox="0 0 1194 64"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <defs>
      <filter
        id="filter1_ddi_114_2661"
        x="0"
        y="-20"
        width="1194"
        height="180"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.14053 0 0 0 0 0.171409 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_114_2661"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.882353 0 0 0 0 0.223529 0 0 0 0 0.258824 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_dropShadow_114_2661"
          result="effect2_dropShadow_114_2661"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_114_2661"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.5" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 1 0 0 0 0 0.164682 0 0 0 0 0.111363 0 0 0 1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect3_innerShadow_114_2661"
        />
      </filter>
    </defs>
    <path
      className="loader-bar-background"
      opacity="0"
      d="M3 61.5C24.3333 61.5 163.8 61.5 203 61.5C252 61.5 283.5 3 328.5 3C364.5 3 426.5 3 465 3H1191"
      stroke="#1E2531"
      strokeWidth="5"
      strokeLinecap="round"
    />
    <path
      className="loader-bar"
      filter="url(#filter1_ddi_114_2661)"
      d="M3 61.5C24.3333 61.5 163.8 61.5 203 61.5C252 61.5 283.5 3 328.5 3C364.5 3 426.5 3 465 3H1191"
      stroke="#FF8D86"
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
)

export default LoadingBar
