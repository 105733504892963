import gsap from 'gsap'
import React, { FC, useEffect, useRef, useState } from 'react'
import {
  GlitchedImage,
  StyledGlitchedBackground,
} from './LoadingScreen.components'

const glitchKeyframes = [
  {
    '0%': {
      opacity: 1,
      transform: 'translate3d(-10px,0,0)',
      clipPath: 'polygon(0 25%, 100% 25%, 100% 30%, 0 30%)',
    },
    '12%': {
      clipPath: 'polygon(0 3%, 100% 3%, 100% 3%, 0 3%)',
    },
    '20%': {
      clipPath: 'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
    },
    '28%': {
      clipPath: 'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
    },
    '36%': {
      clipPath: 'polygon(0 40%, 100% 40%, 100% 40%, 0 40%)',
    },
    '44%': {
      clipPath: 'polygon(0 52%, 100% 52%, 100% 59%, 0 59%)',
    },
    '52%': {
      clipPath: 'polygon(0 60%, 100% 60%, 100% 60%, 0 60%)',
    },
    '60%': {
      clipPath: 'polygon(0 75%, 100% 75%, 100% 75%, 0 75%)',
    },
    '68%': {
      clipPath: 'polygon(0 65%, 100% 65%, 100% 40%, 0 40%)',
    },
    '76%': {
      clipPath: 'polygon(0 45%, 100% 45%, 100% 50%, 0 50%)',
    },
    '80%': {
      clipPath: 'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
    },
    '88%': {
      opacity: 1,
      transform: 'translate3d(-10px,0,0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate3d(0,0,0)',
      clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
    },
  },
  {
    '0%': {
      opacity: 1,
      transform: 'translate3d(-10px,0,0)',
      clipPath: 'polygon(0 25%, 100% 25%, 100% 30%, 0 30%)',
    },
    '12%': {
      clipPath: 'polygon(0 3%, 100% 3%, 100% 3%, 0 3%)',
    },
    '20%': {
      clipPath: 'polygon(0 5%, 100% 5%, 100% 20%, 0 20%)',
    },
    '28%': {
      clipPath: 'polygon(0 20%, 100% 20%, 100% 20%, 0 20%)',
    },
    '80%': {
      clipPath: 'polygon(0 14%, 100% 14%, 100% 33%, 0 33%)',
    },
    '88%': {
      opacity: 1,
      transform: 'translate3d(-10px,0,0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate3d(0,0,0)',
      clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
    },
  },
  {
    '0%': {
      opacity: 0.5,
      transform: 'translate3d(-10px,0,0)',
      clipPath: 'polygon(0 2%, 100% 2%, 100% 5%, 0 5%)',
    },
    '2%': {
      clipPath: 'polygon(0 15%, 100% 15%, 100% 15%, 0 15%)',
    },
    '4%': {
      clipPath: 'polygon(0 10%, 100% 10%, 100% 20%, 0 20%)',
    },
    '6%': {
      clipPath: 'polygon(0 1%, 100% 1%, 100% 2%, 0 2%)',
    },
    '8%': {
      clipPath: 'polygon(0 33%, 100% 33%, 100% 33%, 0 33%)',
    },
    '10%': {
      clipPath: 'polygon(0 44%, 100% 44%, 100% 44%, 0 44%)',
    },
    '12%': {
      clipPath: 'polygon(0 50%, 100% 50%, 100% 20%, 0 20%)',
    },
    '14%': {
      clipPath: 'polygon(0 70%, 100% 70%, 100% 70%, 0 70%)',
    },
    '16%': {
      clipPath: 'polygon(0 80%, 100% 80%, 100% 80%, 0 80%)',
    },
    '18%': {
      clipPath: 'polygon(0 50%, 100% 50%, 100% 55%, 0 55%)',
    },
    '20%': {
      clipPath: 'polygon(0 70%, 100% 70%, 100% 80%, 0 80%)',
    },
    '22%': {
      opacity: 0.7,
      transform: 'translate3d(-10px,0,0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate3d(0,0,0)',
      clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
    },
  },
  {
    '0%': {
      opacity: 0.7,
      transform: 'translate3d(-10px,0,0)',
      clipPath: 'polygon(25% 0, 30% 0, 30% 100%, 25% 100%)',
    },
    '12%': {
      clipPath: 'polygon(3% 0, 3% 0, 3% 100%, 3% 100%)',
    },
    '20%': {
      clipPath: 'polygon(5% 0, 20% 0, 20% 100%, 5% 100%)',
    },
    '28%': {
      clipPath: 'polygon(20% 0, 20% 0, 20% 100%, 20% 100%)',
    },
    '36%': {
      clipPath: 'polygon(40% 0, 40% 0, 40% 100%, 40% 100%)',
    },
    '68%': {
      clipPath: 'polygon(65% 0, 40% 0, 40% 100%, 65% 100%)',
    },
    '76%': {
      clipPath: 'polygon(45% 0, 50% 0, 50% 100%, 65% 100%)',
    },
    '80%': {
      clipPath: 'polygon(14% 0, 33% 0, 33% 100%, 14% 100%)',
    },
    '88%': {
      opacity: 0.5,
      transform: 'translate3d(-10px,0,0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate3d(0,0,0)',
      clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
    },
  },
  {
    '0%': {
      opacity: 0.3,
      transform: 'translate3d(-10px,0,0)',
      clipPath: 'polygon(25% 0, 30% 0, 30% 100%, 25% 100%)',
    },
    '12%': {
      clipPath: 'polygon(3% 0, 3% 0, 3% 100%, 3% 100%)',
    },
    '20%': {
      clipPath: 'polygon(5% 0, 20% 0, 20% 100%, 5% 100%)',
    },
    '28%': {
      clipPath: 'polygon(20% 0, 20% 0, 20% 100%, 20% 100%)',
    },
    '36%': {
      clipPath: 'polygon(40% 0, 40% 0, 40% 100%, 40% 100%)',
    },
    '78%': {
      clipPath: 'polygon(14% 0, 33% 0, 33% 100%, 14% 100%)',
    },
    '88%': {
      opacity: 0.7,
      transform: 'translate3d(0,-10px,0)',
    },
    '100%': {
      opacity: 0,
      transform: 'translate3d(0,0,0)',
      clipPath: 'polygon(0 0, 0 0, 0 0, 0 0)',
    },
  },
]

const GlitchedBackground: FC = () => {
  const [randomGlitch, setRandomGlitch] = useState({
    randomGlitch: glitchKeyframes[0],
    randomDelay: 5,
  })
  const glitchedBackgroundRef = useRef(null)

  useEffect(() => {
    const backgroundOpacityAnimation = gsap.to(glitchedBackgroundRef.current, {
      autoAlpha: 1,
      duration: 5,
    })

    return () => {
      backgroundOpacityAnimation.kill()
    }
  }, [])

  useEffect(() => {
    const glitch = glitchedBackgroundRef.current.querySelector('.glitch')

    const glitchBackgroundAnimation = gsap.to(glitch, {
      keyframes: randomGlitch.randomGlitch,
      delay: randomGlitch.randomDelay,
      onComplete: () => {
        setRandomGlitch({
          randomGlitch: gsap.utils.random(glitchKeyframes),
          randomDelay: gsap.utils.random([2, 3, 4, 5, 6]),
        })
      },
    })

    return () => {
      glitchBackgroundAnimation.kill()
    }
  }, [randomGlitch])

  return (
    <StyledGlitchedBackground ref={glitchedBackgroundRef}>
      <GlitchedImage />
      <GlitchedImage className="glitch" />
    </StyledGlitchedBackground>
  )
}

export default GlitchedBackground
