import * as THREE from 'three'
import { useFrame, useLoader, useThree } from '@react-three/fiber'
import React, { useEffect, useMemo, useRef } from 'react'
import { vertexShader, fragmentShader } from './shaders'
import { isClientSide } from '../../utils/helpers'
import EarthTexture from '../../assets/images/earth.jpg'
import { TextureLoader } from 'three'

const SphereShaderMaterial = {
  uniforms: {
    uTime: { value: 0 },
    uPixelRatio: {
      value: Math.min(isClientSide() && window.devicePixelRatio, 2),
    },
    uTexture: { value: null },
    uMouse: { value: new THREE.Vector2(0, 0) },
    uMouseIn: { value: 0 },
  },
  vertexShader,
  fragmentShader,
}

const MainSphere = () => {
  const shaderMaterial = useRef(null)
  const pointsRef = useRef(null)

  const { size } = useThree()

  const earthTexture = useLoader(TextureLoader, EarthTexture)

  const [randomSizes] = useMemo(() => {
    const sizes = []

    const num = 40401

    for (let i = 0; i < num; i++) {
      const randomSize = Math.random()

      sizes.push(randomSize)
    }

    const randomSizes = new Float32Array(sizes)

    return [randomSizes]
  }, [])

  const handleResize = () => {
    shaderMaterial.current.uniforms.uPixelRatio.value = Math.min(
      window.devicePixelRatio,
      2,
    )
  }

  useEffect(() => {
    shaderMaterial.current.uniforms.uTexture.value = earthTexture

    window.addEventListener('resize', handleResize)

    return window.removeEventListener('resize', handleResize)
  }, [])

  useFrame((_, delta) => {
    shaderMaterial.current.uniforms.uTime.value += delta * 3
    pointsRef.current.rotation.y += delta / 15
  })

  return (
    <points scale={390} ref={pointsRef} position={[size.width / 3, 0, 0]}>
      <sphereBufferGeometry args={[1, 150, 150]}>
        <bufferAttribute
          count={randomSizes.length}
          array={randomSizes}
          attachObject={['attributes', 'aRandomSizes']}
          itemSize={1}
        ></bufferAttribute>
      </sphereBufferGeometry>
      <shaderMaterial
        ref={shaderMaterial}
        attach="material"
        args={[SphereShaderMaterial]}
        transparent={true}
        depthWrite={false}
        depthTest={false}
        blending={THREE.AdditiveBlending}
      />
    </points>
  )
}

export default MainSphere
