import gsap from 'gsap'
import React, { FC, useContext, useEffect, useRef } from 'react'
import { CTAButton } from '../Shared/Shared.components'
import { Body2, H5 } from '../Typography/Typography.components'
import {
  ContentWrapper,
  OverflowServicesContainer,
  ServiceName,
  ServicesButtons,
  ServicesContainer,
  ServicesSlider,
  Slide,
  StyledServiceButton,
  StyledServices,
  StyledServicesOutline,
  Video,
} from './Services.components'

import DroneVideo from '../../assets/videos/drone.mp4'
import BoxVideo from '../../assets/videos/box.mp4'
import MicroscopeVideo from '../../assets/videos/microscope.mp4'
import LightVideo from '../../assets/videos/light.mp4'
import { MyContext } from '../../utils/Context'

const Services: FC = () => {
  const servicesRef = useRef(null)
  const slideRef = useRef(0)
  const slideAnimationRef = useRef(false)

  const context = useContext(MyContext)

  useEffect(() => {
    const slides = servicesRef.current.querySelectorAll('.services-slide')
    slideAnimationRef.current = true

    gsap.to(slides[slideRef.current], {
      autoAlpha: 0,
      duration: 0.3,
    })

    gsap.to(slides[context.currentService], {
      autoAlpha: 1,
      duration: 0.3,
      onComplete: () => {
        slideAnimationRef.current = false
        slideRef.current = context.currentService
      },
    })
  }, [context.currentService])

  const handleNext = () => {
    const serviceNames = servicesRef.current.querySelectorAll('.service-name')

    if (context.currentService === 3 || slideAnimationRef.current) {
      return
    } else {
      context.setCurrentService(slide => slide + 1)

      const currentService = serviceNames[context.currentService]
      const currentServiceWidth = currentService.getBoundingClientRect().width

      gsap.to(serviceNames, {
        x: `-=${currentServiceWidth + 32}`,
        duration: 0.2,
      })
    }
  }

  const handlePrev = () => {
    const serviceNames = servicesRef.current.querySelectorAll('.service-name')

    if (context.currentService === 0 || slideAnimationRef.current) {
      return
    } else {
      context.setCurrentService(slide => slide - 1)

      const currentService = serviceNames[context.currentService - 1]
      const currentServiceWidth = currentService.getBoundingClientRect().width

      gsap.to(serviceNames, {
        x: `+=${currentServiceWidth + 32}`,
        duration: 0.2,
      })
    }
  }

  return (
    <OverflowServicesContainer>
      <StyledServices ref={servicesRef} className="section-3">
        <ServicesContainer>
          <StyledServicesOutline />
          <H5>Services</H5>
          <ServicesSlider>
            <ServiceName
              className="service-name"
              // onClick={() => handleServiceNameClick(0)}
              active={context.currentService === 0}
            >
              Drones
            </ServiceName>
            <ServiceName
              className="service-name"
              // onClick={() => handleServiceNameClick(1)}
              active={context.currentService === 1}
            >
              Green technology
            </ServiceName>
            <ServiceName
              className="service-name"
              // onClick={() => handleServiceNameClick(2)}
              active={context.currentService === 2}
            >
              Healthcare innovation
            </ServiceName>
            <ServiceName
              className="service-name"
              // onClick={() => handleServiceNameClick(3)}
              active={context.currentService === 3}
            >
              Software development
            </ServiceName>
          </ServicesSlider>
          <ContentWrapper>
            <Slide className="services-slide">
              <Body2>
                We truly believe that drones are the future of medical
                transportation, which is why we're on a mission to fly our first
                drone next year for the purposes of blood sample delivery. If
                you are interested in this idea and you would like to
                collaborate, please do reach out. Let’s create the future and
                disrupt the medical industry together!
              </Body2>
              <CTAButton
                onClick={() => context.handleModal()}
                className="cursor-reactive"
              >
                Talk to us
              </CTAButton>
            </Slide>
            <Slide className="services-slide">
              <Body2>
                At ML Innovation Hub we are exploring green technology to
                improve medical transportation and contribute to a greener
                future. We have already tested electric bicycles and scooters,
                however, we’ve noticed that technology isn’t developed enough
                for the needs in our sector. We currently use electric cars but
                we want to keep exploring other options. If you’re interested in
                green technology, please reach out.
              </Body2>
              <CTAButton
                onClick={() => context.handleModal()}
                className="cursor-reactive"
              >
                Click here for more thoughts
              </CTAButton>
            </Slide>
            <Slide className="services-slide">
              <Body2>
                We’re currently working on various projects such as using
                devices to take your blood and check potassium levels
                needle-free. We’re always excited to hear about new ideas to
                improve this sector while reducing the stress for patients. We
                want to improve the experience of routine tests and medical
                procedures. If you work in this industry and have some exciting
                ideas, get in touch!
              </Body2>
            </Slide>
            <Slide className="services-slide">
              <Body2>
                We are continuously experimenting with a variety of software
                solutions to improve healthcare and medical logistics. Recently,
                we’ve been working on developing a piece of software which
                automates the courier control of daily deliveries. We'd love to
                offer it to any interested companies in the industry. This
                technology will replace courier controllers and allow for a
                smoother and more efficient delivery process! If this sounds
                like something you’d be interested in getting involved in,
                please get in touch.
              </Body2>
            </Slide>
          </ContentWrapper>
          <ServicesButtons>
            <StyledServiceButton
              onClick={handleNext}
              disabled={context.currentService === 3}
              className="service-arrow service-arrow-right cursor-reactive"
            ></StyledServiceButton>
            <StyledServiceButton
              onClick={handlePrev}
              disabled={context.currentService === 0}
              className="service-arrow service-arrow-left cursor-reactive"
            ></StyledServiceButton>
          </ServicesButtons>
          <Video
            autoPlay={true}
            playsInline={true}
            muted={true}
            loop={true}
            src={DroneVideo}
          />
          <Video
            autoPlay={true}
            playsInline={true}
            muted={true}
            loop={true}
            src={BoxVideo}
          />
          <Video
            autoPlay={true}
            playsInline={true}
            muted={true}
            loop={true}
            src={MicroscopeVideo}
          />
          <Video
            autoPlay={true}
            playsInline={true}
            muted={true}
            loop={true}
            src={LightVideo}
          />
        </ServicesContainer>
      </StyledServices>
    </OverflowServicesContainer>
  )
}

export default Services
