import { useEffect, useState } from 'react'

export const useVideosLoad = () => {
  const [videosLoaded, setVideosLoaded] = useState(false)

  useEffect(() => {
    const videos = document.querySelectorAll('video')

    const promises = []

    videos.forEach(video => {
      const promise = new Promise(res => {
        video.onplaying = () => {
          if (video.readyState >= 4) {
            res(true)
          }
        }
      })
      promises.push(promise)
    })

    Promise.all(promises)
      .then(() => setVideosLoaded(true))
      .then(() => {
        videos.forEach(video => {
          video.onplaying = undefined
        })
      })
  }, [])

  return videosLoaded
}
