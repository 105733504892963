import gsap from 'gsap'
import React, { FC, useEffect, useRef } from 'react'
import { MEDIA_QUERIES } from '../../utils/constants'
import { useMediaQuery } from '../../utils/hooks/useMediaQuery'
import CursorPosition from '../Cursor/CursorPosition'
import { Body2, H1, Subtitle1 } from '../Typography/Typography.components'
import {
  BottomContainer,
  HeroBox,
  HeroLoader,
  HeroUnderline,
  Plus,
  Socials,
  SocialsUnderline,
  StyledHero,
  TextBox,
  TextBoxOutline,
} from './Hero.components'

interface HeroProps {
  animateHero: boolean
}

const Hero: FC<HeroProps> = props => {
  const heroTimeline = useRef(null)
  const heroRef = useRef(null)

  const isTablet = useMediaQuery(
    `(max-width: ${MEDIA_QUERIES.tablet}px)`,
    true,
    false,
  )
  const isSmallTablet = useMediaQuery(
    `(max-width: ${MEDIA_QUERIES.smallTablet}px)`,
    true,
    false,
  )

  useEffect(() => {
    if (props.animateHero) {
      const heroTextBox = heroRef.current.querySelector('.hero-text-box')
      const heroCursorPosition = heroRef.current.querySelector(
        '.hero-cursor-position',
      )
      const heroLoaderPaths =
        heroRef.current.querySelectorAll('.hero-loader path')
      const heroLongUnderline = heroRef.current.querySelector('.hero-underline')
      const heroShortUnderline = heroRef.current.querySelector(
        '.hero-underline span',
      )
      const socialLinks = heroRef.current.querySelectorAll('.social-link')
      const socialsLongUnderline =
        heroRef.current.querySelector('.socials-underline')
      const socialsShortUnderline = heroRef.current.querySelector(
        '.socials-underline span',
      )

      heroTimeline.current = gsap
        .timeline()
        .add('mainContent')
        .to(
          heroTextBox,
          {
            scale: 1,
            transformOrigin: 'left',
            duration: 0.6,
            ease: 'cubic-bezier(0.4, 0.0, 0.2, 1);',
          },
          'mainContent',
        )
        .to(
          heroCursorPosition,
          {
            opacity: 1,
            duration: isTablet ? 0 : 0.6,
          },
          'mainContent',
        )
        .to(
          Array.from(heroLoaderPaths).reverse(),
          {
            opacity: 1,
            stagger: 0.01,
          },
          'mainContent',
        )
        .to(
          socialLinks,
          {
            opacity: 1,
            stagger: 0.1,
          },
          'mainContent',
        )
        .add('underlines')
        .to(
          heroLongUnderline,
          {
            width: '220px',
            duration: isSmallTablet ? 0 : 0.4,
          },
          'underlines',
        )
        .to(heroShortUnderline, {
          width: '100px',
          duration: isSmallTablet ? 0 : 0.4,
        })
        .to(
          socialsLongUnderline,
          {
            height: isTablet ? '22px' : '200px',
            duration: 0.5,
          },
          'underlines',
        )
        .to(socialsShortUnderline, {
          height: '50px',
          duration: 0.2,
        })
    }

    return () => {
      heroTimeline?.current?.kill()
    }
  }, [props.animateHero])

  return (
    <StyledHero ref={heroRef}>
      <HeroBox>
        <TextBox className="hero-text-box">
          <H1>
            Medical logistics <span>innovation</span> hub{' '}
          </H1>
          <Body2>
            The place to find the most exciting trends in medical technology,
            and to share all your innovative ideas. Whether you’re interested in
            flying drones for medical transportation, technology to draw blood
            needle-free or you have other ideas to disrupt the medical industry
            - we’d love to chat about it!
          </Body2>
          <TextBoxOutline />
          <Plus className="plus-one" />
          <Plus className="plus-two" />
        </TextBox>
        <BottomContainer>
          {!isTablet && <CursorPosition className="hero-cursor-position" />}
          <HeroLoader className="hero-loader" />
          {!isSmallTablet && (
            <HeroUnderline className="hero-underline">
              <span></span>
            </HeroUnderline>
          )}
        </BottomContainer>
      </HeroBox>
      <Socials>
        {/* <Subtitle1
          as="a"
          href="#"
          className="social-link cursor-reactive hover-indicator hover-indicator--left"
        >
          FB
        </Subtitle1>
        <Subtitle1
          as="a"
          href="#"
          className="social-link cursor-reactive hover-indicator hover-indicator--left"
        >
          YT
        </Subtitle1>
        <Subtitle1
          as="a"
          href="#"
          className="social-link cursor-reactive hover-indicator hover-indicator--left"
        >
          TW
        </Subtitle1> */}
        <Subtitle1
          as="a"
          href="https://www.linkedin.com/company/medical-logistics-innovation-hub/about/?viewAsMember=true"
          className="social-link cursor-reactive hover-indicator hover-indicator--left"
        >
          IN
        </Subtitle1>
        <SocialsUnderline className="socials-underline">
          <span></span>
        </SocialsUnderline>
      </Socials>
    </StyledHero>
  )
}

export default Hero
