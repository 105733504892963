import styled, { css } from 'styled-components'
import { MEDIA_QUERIES, REM_VALUES } from '../../utils/constants'
import { CTAButton, Wrapper } from '../Shared/Shared.components'
import HamburgerBackgroundImage from '../../assets/images/inline/hamburger_background.svg'

export const StyledHeader = styled.header`
  width: 100%;
  z-index: 2;
  position: relative;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`

export const HamburgerButton = styled.div<{ active: boolean }>`
  position: relative;
  width: 36px;
  height: 36px;
  left: -7px;
  z-index: 1;
  display: none;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    display: block;
  }

  .hamburger-btn-line {
    background: ${({ theme }) => theme.colors.white};
    width: 22px;
    height: 2px;
    position: absolute;
    transition: all 0.2s ease-in-out;

    &.line-one {
      top: 12px;
      left: 50%;
      transform: rotateZ(0deg) translate(-50%, -1px);
    }

    &.line-two {
      top: 22px;
      left: 50%;
      transform: rotateZ(0deg) translate(-50%, -1px);
    }
  }

  ${({ active }) =>
    active &&
    css`
      .hamburger-btn-line {
        &.line-one {
          transform: translate(-50%, 5px) rotateZ(45deg);
        }

        &.line-two {
          transform: translate(-50%, -5px) rotateZ(-45deg);
        }
      }
    `}

  .hamburger-btn-underline {
    background: ${({ theme }) => theme.colors.white};
    width: 1px;
    height: 27px;
    position: absolute;

    &.line-one {
      right: -10px;
      top: 12px;
    }

    &.line-two {
      top: 100%;
      height: 32px;
      transform: rotateZ(60deg);
      transform-origin: top;
    }
  }
`

export const Nav = styled.nav`
  position: relative;
  display: inline-block;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    display: none;
  }
`

export const NavUnderline = styled.div`
  position: absolute;
  width: 0;
  height: 1px;
  background: ${({ theme }) => theme.colors.white};
  left: 0;
  right: 0;
  bottom: 0;
  width: 0;

  span {
    position: absolute;
    display: block;
    content: '';
    width: 0;
    height: 1px;
    background: ${({ theme }) => theme.colors.white};
    left: 100%;
    bottom: 0;
    transform: rotateZ(-40deg);
    transform-origin: left;
    width: 0;
  }
`

export const NavList = styled.ul`
  display: flex;
  margin: 0;
  list-style-type: none;
  margin-left: 94px;
  margin-right: 32px;

  li {
    padding: 24px 0;
    margin-bottom: 0;
    opacity: 0;

    p {
      opacity: 0.7;
    }

    &:hover {
      p {
        opacity: 1;
      }
    }
  }

  li + li {
    margin-left: 40px;
  }

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    margin-left: 8px;
    margin-right: 0;
  }
`

export const StyledCTAButton = styled(CTAButton)`
  opacity: 0;
`

export const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
`

export const StyledHamburgerMenu = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
`

export const HamburgerMenuOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.bodyBackground};
  opacity: 0.6;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
`

export const HamburgerMenuNavContainer = styled.div`
  width: 85%;
  height: 100%;
  /* background: black; */
  position: relative;
  z-index: 2;
  overflow: auto;
  padding-top: 92px;
  padding-left: 24px;
  max-width: 390px;
  transform: translateX(-100%);

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding-left: 16px;
  }
`

export const HamburgerMenuNavList = styled.div`
  margin: 0;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  li {
    padding: 20px 0;
    margin-bottom: 0;
    display: inline-block;
    margin-left: 52px;

    p {
      opacity: 0.7;
      font-size: ${REM_VALUES[18]};
      line-height: ${REM_VALUES[24]};
    }

    &:hover {
      p {
        opacity: 1;
      }
    }
  }
`

export const HamburgerBackground = styled(HamburgerBackgroundImage)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`
