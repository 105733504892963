import { isClientSide } from '.'

export const handleNavigationItemClick = section => {
  const sectionToScroll = document.querySelector(`.${section}`)
  const { y } = sectionToScroll.getBoundingClientRect()

  isClientSide() &&
    window.scrollTo({
      top: y + window.scrollY,
      behavior: 'smooth',
    })
}
