import styled, { css } from 'styled-components'
import { Wrapper } from '../Shared/Shared.components'
import ServiceButton from '../../assets/images/inline/arrow_button.svg'
import { Body2, H2, H5 } from '../Typography/Typography.components'
import { MEDIA_QUERIES } from '../../utils/constants'
import ServicesOutline from '../../assets/images/inline/services_outline.svg'

export const OverflowServicesContainer = styled.div`
  overflow: hidden;
  width: 100%;
`

export const StyledServices = styled(Wrapper)`
  padding: 126px 24px 0 24px;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding: 130px 16px 0 16px;
  }
`

export const StyledServicesOutline = styled(ServicesOutline)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    width: 150%;
  }
`

export const ServicesContainer = styled.div`
  position: relative;
  max-width: 1015px;
  margin: 0 auto;
  padding: 40px 0px 64px 88px;

  ${H5} {
    margin-bottom: 8px;
    text-transform: uppercase;
    text-shadow: -1px 0 white, 0 1px white, 1px 0 white, 0 -1px white;
    color: black;
  }

  @media (max-width: ${MEDIA_QUERIES.mediumTablet}px) {
    padding: 40px 0px 64px 40px;
    margin: 0 32px;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    padding: 34px 0px 70px 32px;
    margin: 0 0 0 32px;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    margin: 0;
    padding: 16px 0 42px 16px;
  }
`

export const ServicesButtons = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: inline-flex;
  flex-direction: column;
`

export const StyledServiceButton = styled(ServiceButton)`
  display: inline-block;
  margin: 0;

  .arrow-fill {
    transition: all 0.2s ease-in-out;
  }

  &:hover .arrow-fill {
    fill: #8ffff9;
  }

  &.service-arrow-right {
    margin-bottom: -2px;
  }

  &.service-arrow-left {
    transform: rotateZ(180deg) rotateX(180deg);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      &:hover .arrow-fill {
        fill: initial;
      }

      .arrow-content-center {
        stroke: #8ffff9;
        opacity: 0.52;
      }

      .arrow-content {
        fill: #8ffff9;
        opacity: 0.52;
      }
    `}

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    width: 76px;
    height: 76px;
  }
`

export const ServicesSlider = styled.div`
  display: flex;
`

export const ServiceName = styled(H2)<{ active?: boolean }>`
  white-space: nowrap;
  color: #ff8d86;
  opacity: 0.3;
  text-shadow: 0px 0px 3px #e13942, 0px 0px 12px #ff242c;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
  margin-right: 32px;

  /* &:hover {
    opacity: 0.6;
  } */

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.white};
      opacity: 1;
      text-shadow: 0px 0px 3px #e13942, 0px 0px 12px #ff242c;
    `}
`

export const ContentWrapper = styled.div`
  display: grid;
  grid-template: 1fr / 1fr;

  ${Body2} {
    max-width: 608px;
    margin: 64px 0 40px;
  }

  @media (max-width: ${MEDIA_QUERIES.mediumTablet}px) {
    ${Body2} {
      max-width: 442px;
    }
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    ${Body2} {
      max-width: 362px;
    }
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    ${Body2} {
      margin: 24px 0 40px;
      width: 70%;
    }
  }
`

export const Video = styled.video`
  display: inline-block;
  position: absolute;
  width: 522px;
  height: 294px;
  right: 17%;
  bottom: -28%;
  opacity: 0;
  pointer-events: none;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    right: 70px;
  }
  @media (max-width: ${MEDIA_QUERIES.mediumTablet}px) {
    bottom: -45%;
  }
  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    width: 372px;
    height: 210px;
    bottom: -10%;
  }
  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    bottom: -30%;
    right: -20px;
  }
`

export const Slide = styled.div`
  grid-row: 1 / -1;
  grid-column: 1 / -1;
  opacity: 0;
  visibility: hidden;
`
