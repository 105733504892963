import { PerspectiveCamera } from '@react-three/drei'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useRef } from 'react'

const Camera = () => {
  const cameraRef = useRef(null)
  const scrollRef = useRef(0)
  const { size } = useThree()

  const cameraPosition = 1500

  useFrame(() => {
    cameraRef.current.position.y = -scrollRef.current
  })

  const handleScroll = () => {
    scrollRef.current = window.scrollY
  }

  const handleResize = () => {
    cameraRef.current.aspect = window.innerWidth / window.innerHeight
    cameraRef.current.fov =
      2 * Math.atan(window.innerHeight / 2 / cameraPosition) * (180 / Math.PI)
    cameraRef.current.updateProjectionMatrix()
  }

  useEffect(() => {
    cameraRef.current.fov =
      2 * Math.atan(size.height / 2 / cameraPosition) * (180 / Math.PI)
    cameraRef.current.updateProjectionMatrix()

    handleScroll()

    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <PerspectiveCamera
      ref={cameraRef}
      makeDefault
      near={100}
      far={2000}
      position={[0, 0, cameraPosition]}
    />
  )
}

export default Camera
