import gsap from 'gsap'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { CTAButton } from '../Shared/Shared.components'
import { Body2, H2, H6, Subtitle1 } from '../Typography/Typography.components'

import {
  CollaborationContainer,
  CollaborationContentContainer,
  StyledWrapper,
  Frame,
  FrameOutter,
  FrameInner,
  FrameInnerMedium,
  FrameInnerMobile,
  LoaderContainer,
  ContentContainer,
  StyledCollaborationLoader,
  ProgressContainer,
} from './Collaboration.components'
import { MyContext } from '../../utils/Context'

const Collaboration: FC = () => {
  const collaborationContainerRef = useRef(null)
  const loaderAnimationRef = useRef(null)
  const [progress, setProgress] = useState(0)

  const context = useContext(MyContext)

  useEffect(() => {
    const loaderRects =
      collaborationContainerRef.current.querySelectorAll('rect')
    const loaderContainer =
      collaborationContainerRef.current.querySelector('.loader-container')
    const contentContainer =
      collaborationContainerRef.current.querySelector('.content-container')
    const collaborationContentContainer =
      collaborationContainerRef.current.querySelector(
        '.collaboration-content-container',
      )
    const frameLeft = collaborationContainerRef.current.querySelector(
      '.collaboration-frame-left',
    )
    const frameRight = collaborationContainerRef.current.querySelector(
      '.collaboration-frame-right',
    )

    const lineDiagonalTopOutter =
      collaborationContainerRef.current.querySelectorAll(
        '.line-diagonal--top--outter',
      )
    const lineDiagonalBottomOutter =
      collaborationContainerRef.current.querySelectorAll(
        '.line-diagonal--bottom--outter',
      )
    const lineVerticalOutter =
      collaborationContainerRef.current.querySelectorAll(
        '.line-vertical--outter',
      )
    const lineDiagonalTopInner =
      collaborationContainerRef.current.querySelectorAll(
        '.line-diagonal--top--inner',
      )
    const lineDiagonalBottomInner =
      collaborationContainerRef.current.querySelectorAll(
        '.line-diagonal--bottom--inner',
      )
    const lineDiagonalTopInnerMedium =
      collaborationContainerRef.current.querySelectorAll(
        '.line-diagonal--top--inner--medium',
      )
    const lineDiagonalBottomInnerMedium =
      collaborationContainerRef.current.querySelectorAll(
        '.line-diagonal--bottom--inner--medium',
      )
    const lineDiagonalTopInnerMobile =
      collaborationContainerRef.current.querySelectorAll(
        '.line-diagonal--top--inner--mobile',
      )
    const lineDiagonalBottomInnerMobile =
      collaborationContainerRef.current.querySelectorAll(
        '.line-diagonal--bottom--inner--mobile',
      )
    const lineVerticalInner =
      collaborationContainerRef.current.querySelectorAll(
        '.line-vertical--inner',
      )

    loaderAnimationRef.current = gsap.set(loaderRects, {
      opacity: 1,
      stagger: 0.01,
      onUpdate: () => {
        const parsedProgress = Math.round(
          loaderAnimationRef.current.progress() * 100,
        )
        setProgress(parsedProgress)
      },
    })

    gsap.set([lineVerticalInner, lineVerticalOutter], {
      scaleY: 0,
      transformOrigin: '50% 50%',
    })
    gsap.set([lineDiagonalTopInner, lineDiagonalTopOutter], {
      transformOrigin: '0% 100%',
      rotationZ: 19,
    })
    gsap.set([lineDiagonalBottomInner, lineDiagonalBottomOutter], {
      rotationZ: -19,
    })
    gsap.set(lineDiagonalTopInnerMedium, {
      transformOrigin: '0% 100%',
      rotationZ: 24,
    })
    gsap.set(lineDiagonalBottomInnerMedium, {
      rotationZ: -24,
    })
    gsap.set(lineDiagonalTopInnerMobile, {
      transformOrigin: '0% 100%',
      rotationZ: 24,
    })
    gsap.set(lineDiagonalBottomInnerMobile, {
      rotationZ: -24,
    })
    gsap.set(lineDiagonalTopInner, {
      y: 157,
    })
    gsap.set(lineDiagonalTopInnerMedium, {
      y: 195,
    })
    gsap.set(lineDiagonalTopInnerMobile, {
      y: 195,
    })
    gsap.set(lineDiagonalTopOutter, {
      y: 132.5,
    })
    gsap.set(lineDiagonalBottomInner, {
      y: -157,
    })
    gsap.set(lineDiagonalBottomInnerMedium, {
      y: -195,
    })
    gsap.set(lineDiagonalBottomInnerMobile, {
      y: -195,
    })
    gsap.set(lineDiagonalBottomOutter, {
      y: -132.5,
    })

    const collaborationTl = gsap
      .timeline({
        scrollTrigger: {
          trigger: collaborationContainerRef.current,
          start: '-100px 30%',
        },
      })
      .add(loaderAnimationRef.current)
      .to(loaderContainer, {
        autoAlpha: 0,
        duration: 1,
        delay: 0.3,
      })
      .to(
        contentContainer,
        {
          autoAlpha: 1,
          duration: 1.5,
        },
        '>-1',
      )
      .add('start')
      .to(
        [lineVerticalInner, lineVerticalOutter],
        {
          scaleY: 1,
        },
        'start',
      )
      .to(
        [
          lineDiagonalBottomInner,
          lineDiagonalBottomInnerMedium,
          lineDiagonalBottomInnerMobile,
          lineDiagonalBottomOutter,
          lineDiagonalTopInner,
          lineDiagonalTopInnerMedium,
          lineDiagonalTopInnerMobile,
          lineDiagonalTopOutter,
        ],
        {
          rotationZ: 0,
        },
        'start',
      )
      .to(
        [
          lineDiagonalTopInner,
          lineDiagonalTopInnerMedium,
          lineDiagonalTopInnerMobile,
          lineDiagonalTopOutter,
          lineDiagonalBottomInner,
          lineDiagonalBottomInnerMedium,
          lineDiagonalBottomInnerMobile,
          lineDiagonalBottomOutter,
        ],
        {
          y: 0,
        },
        'start',
      )
      .to(
        [frameLeft, frameRight],
        {
          x: 0,
          duration: 0.6,
        },
        '>-1',
      )
      .to(collaborationContentContainer, {
        autoAlpha: 1,
        duration: 1,
        delay: 0.3,
      })

    return () => {
      collaborationTl.kill()
      loaderAnimationRef.current.kill()
    }
  }, [])

  return (
    <StyledWrapper>
      <CollaborationContainer
        className=" section-4"
        ref={collaborationContainerRef}
      >
        <LoaderContainer className="loader-container">
          <H6>We're not finished</H6>
          <StyledCollaborationLoader />
          <ProgressContainer>
            <Subtitle1>Loading data...</Subtitle1>
            <Subtitle1>{progress}%</Subtitle1>
          </ProgressContainer>
        </LoaderContainer>
        <ContentContainer className="content-container">
          <Frame className="collaboration-frame-left">
            <FrameOutter />
            <FrameInner />
            <FrameInnerMedium />
            <FrameInnerMobile />
          </Frame>
          <CollaborationContentContainer className="collaboration-content-container">
            <H2>
              Collaborating With Young <span>Innovators</span>
            </H2>
            <Body2>
              The spirit of Medical Logistics is to encourage innovative ideas
              to make the world a better place.
            </Body2>
            <Body2>
              We are always on the lookout for innovators and support their
              brilliant ideas. One of our goals is to also start offering
              student grants and create a pipeline of new ideas and innovations
              in the world of medical logistics.
            </Body2>
            <CTAButton
              className="hover-reactive"
              onClick={() => context.handleModal()}
              secondary
              fullWidth
              large
            >
              Get in touch
            </CTAButton>
          </CollaborationContentContainer>
          <Frame className="collaboration-frame-right">
            <FrameOutter />
            <FrameInner />
            <FrameInnerMedium />
            <FrameInnerMobile />
          </Frame>
        </ContentContainer>
      </CollaborationContainer>
    </StyledWrapper>
  )
}

export default Collaboration
