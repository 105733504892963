import React, { FC, useState } from 'react'
import { useForm } from 'react-hook-form'

import { Body1, Body2, Button, Caption, H4, Subtitle1, Subtitle2 } from '../Typography/Typography.components'
import LinkedIn from '../../assets/images/inline/linkedin.svg'
// import Twitter from '../../assets/images/inline/twitter.svg'
// import Facebook from '../../assets/images/inline/facebook.svg'

import {
  Author,
  FooterContainer,
  FooterForm,
  FooterInfo,
  FooterLogo,
  FooterLogoSmall,
  FooterMenu,
  FormBody,
  MainFooterContainer,
  Socials,
  StyledWrapper,
} from './Footer.components'
import { CTAButton } from '../Shared/Shared.components'
import InputWrapper from '../Form/InputWrapper'
import { handleNavigationItemClick } from '../../utils/helpers/navigate'
import { ERROR_MESSAGES } from '../../utils/constants'

const Footer: FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onBlur',
  })
  const [loading, setLoading] = useState(false)
  const [requestSuccess, setRequestSuccess] = useState(false)
  const [requestError, setRequestError] = useState(false)

  const handleFormSubmit = data => {
    setLoading(true)
    setRequestError(false)

    const formData = new FormData()

    formData.append('form-name', 'Contact')
    Object.keys(data).forEach(name => {
      if (typeof data[name] === 'object') {
        formData.append(name, data[name][0])
      } else {
        formData.append(name, data[name])
      }
    })

    setTimeout(() => {
      fetch('/contact', {
        method: 'POST',
        body: formData,
      })
        .then(response => {
          if (response.ok) {
            setLoading(false)
            setRequestSuccess(true)
          } else {
            setLoading(false)
            setRequestError(true)
          }
        })
        .catch(() => {
          setLoading(false)
          setRequestError(true)
        })
    }, 2000)
  }

  return (
    <StyledWrapper>
      <FooterContainer className="section-5">
        <MainFooterContainer>
          <FooterInfo>
            <FooterLogo />
            <FooterLogoSmall />
            <Button>
              MED LOGISTICS TECHNOLOGY LIMITED <br /> Registered in England,{' '}
              <br /> number 13698326
            </Button>
            <Button>info@medicallogistics.tech</Button>
            <Socials>
              <a
                className="cursor-reactive"
                href="https://www.linkedin.com/company/medical-logistics-innovation-hub/about/?viewAsMember=true"
              >
                <LinkedIn />
              </a>
              {/* <a className="cursor-reactive" href="#">
                <Twitter />
              </a>
              <a className="cursor-reactive" href="#">
                <Facebook />
              </a> */}
            </Socials>
            <Author>Made by <a className='cursor-reactive' href="https://gorrion.io">Gorrion</a></Author>
          </FooterInfo>
          <FooterMenu>
            <Button
              onClick={() => handleNavigationItemClick('section-1')}
              className="cursor-reactive hover-indicator hover-indicator--left"
            >
              Home
            </Button>
            <Button
              onClick={() => handleNavigationItemClick('section-2')}
              className="cursor-reactive hover-indicator hover-indicator--left"
            >
              About us
            </Button>
            <Button
              onClick={() => handleNavigationItemClick('section-3')}
              className="cursor-reactive hover-indicator hover-indicator--left"
            >
              Services
            </Button>
            <Button
              onClick={() => handleNavigationItemClick('section-4')}
              className="cursor-reactive hover-indicator hover-indicator--left"
            >
              Collaboration
            </Button>
          </FooterMenu>
        </MainFooterContainer>
        <FooterForm>
          <H4>Talk to us</H4>
          <FormBody
            name="Contact"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
            autoComplete="off"
            onSubmit={handleSubmit(handleFormSubmit)}
          >
            <input type="hidden" name="form-name" value="Contact" />
            <InputWrapper
              error={errors.email}
              disabled={requestSuccess || requestError}
              inputName="E-mail address"
            >
              <input
                aria-label="Email"
                disabled={requestSuccess || requestError}
                name="email"
                {...register('email', {
                  required: { value: true, message: ERROR_MESSAGES.REQUIRED },
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: 'Invalid email address',
                  },
                })}
                className="cursor-reactive"
                placeholder="Please enter e-mail"
              />
            </InputWrapper>
            <InputWrapper
              error={errors.message}
              disabled={requestSuccess || requestError}
              inputName="Message"
            >
              <textarea
                disabled={requestSuccess || requestError}
                aria-label="Message"
                name="message"
                {...register('message', {
                  required: { value: true, message: ERROR_MESSAGES.REQUIRED },
                })}
                className="cursor-reactive"
                placeholder="Please enter message"
              />
            </InputWrapper>
            {requestError && (
              <Caption className="error-message">
                Failed, please try again later.
              </Caption>
            )}
            <CTAButton
              disabled={requestSuccess || requestError || loading}
              loading={loading ? 'true' : undefined}
              type="submit"
              className="cursor-reactive"
              secondary
              fullWidth
            >
              {loading
                ? 'Sending'
                : requestSuccess
                ? 'Message sent'
                : 'Send message'}
            </CTAButton>
          </FormBody>
        </FooterForm>
      </FooterContainer>
    </StyledWrapper>
  )
}

export default Footer
