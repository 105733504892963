import gsap from 'gsap'
import React, { FC, useEffect, useRef } from 'react'
import { handleNavigationItemClick } from '../../utils/helpers/navigate'
import { Button } from '../Typography/Typography.components'
import {
  HamburgerBackground,
  HamburgerMenuNavContainer,
  HamburgerMenuNavList,
  HamburgerMenuOverlay,
  StyledHamburgerMenu,
} from './Header.components'

interface HamburgerMenuProps {
  active?: boolean
  handleMenu?: () => void
}

const HamburgerMenu: FC<HamburgerMenuProps> = ({ active, handleMenu }) => {
  const hamburgerMenuRef = useRef(null)
  const hamburgerMenuTimeline = useRef(null)

  useEffect(() => {
    const overlay = hamburgerMenuRef.current.querySelector(
      '.hamburger-menu-nav-overlay',
    )
    const nav = hamburgerMenuRef.current.querySelector(
      '.hamburger-menu-nav-container',
    )

    if (active) {
      hamburgerMenuTimeline.current = gsap
        .timeline()
        .set(hamburgerMenuRef.current, {
          autoAlpha: 1,
        })
        .to(overlay, {
          autoAlpha: 0.6,
          duration: 0.2,
        })
        .to(nav, {
          x: 0,
          duration: 0.2,
          ease: 'ease.in',
        })
    } else {
      hamburgerMenuTimeline.current = gsap
        .timeline()
        .to(nav, {
          x: '-100%',
          duration: 0.2,
          ease: 'ease.out',
        })
        .to(overlay, {
          autoAlpha: 0,
          duration: 0.2,
        })
        .set(hamburgerMenuRef.current, {
          autoAlpha: 0,
        })
    }
  }, [active])

  const handleHamburgerMenuItemClick = section => {
    handleMenu()

    handleNavigationItemClick(section)
  }

  return (
    <StyledHamburgerMenu ref={hamburgerMenuRef}>
      <HamburgerMenuNavContainer className="hamburger-menu-nav-container">
        <HamburgerMenuNavList>
          <li
            onClick={() => handleHamburgerMenuItemClick('section-1')}
            className="cursor-reactive hover-indicator hover-indicator--left"
          >
            <Button>Home</Button>
          </li>
          <li
            onClick={() => handleHamburgerMenuItemClick('section-2')}
            className="cursor-reactive hover-indicator hover-indicator--left"
          >
            <Button>About us</Button>
          </li>
          <li
            onClick={() => handleHamburgerMenuItemClick('section-3')}
            className="cursor-reactive hover-indicator hover-indicator--left"
          >
            <Button>Services</Button>
          </li>
          <li
            onClick={() => handleHamburgerMenuItemClick('section-4')}
            className="cursor-reactive hover-indicator hover-indicator--left"
          >
            <Button>Collaboration</Button>
          </li>
          <li
            onClick={() => handleHamburgerMenuItemClick('section-5')}
            className="cursor-reactive hover-indicator hover-indicator--left"
          >
            <Button>Contact</Button>
          </li>
        </HamburgerMenuNavList>
        <HamburgerBackground />
      </HamburgerMenuNavContainer>
      <HamburgerMenuOverlay
        onClick={() => handleMenu()}
        className="hamburger-menu-nav-overlay"
      />
    </StyledHamburgerMenu>
  )
}

export default HamburgerMenu
