import styled from 'styled-components'
import { Wrapper } from '../Shared/Shared.components'
import FrameOutterImage from '../../assets/images/inline/collaborators_frame_outter.svg'
import FrameInnerImage from '../../assets/images/inline/collaborators_frame_inner.svg'
import FrameInnerMediumImage from '../../assets/images/inline/collaborators_frame_inner_medium.svg'
import FrameInnerMobileImage from '../../assets/images/inline/collaborators_frame_inner_mobile.svg'
import { Body2, H2, H6, Subtitle1 } from '../Typography/Typography.components'
import { MEDIA_QUERIES } from '../../utils/constants'
import CollaborationLoader from '../../assets/images/inline/collaboration_loader.svg'

export const StyledWrapper = styled(Wrapper)`
  display: flex;
  justify-content: space-between;
  padding-top: 346px;
`

export const CollaborationContainer = styled.div`
  position: relative;
  width: 100%;
  display: grid;
  grid-template: 1fr / 1fr;
`

export const LoaderContainer = styled.div`
  grid-row: 1/-1;
  grid-column: 1/-1;
  justify-self: center;
  align-self: center;

  ${Subtitle1}:first-of-type {
    opacity: 0.6;
  }

  ${H6} {
    margin: 0;
    text-transform: uppercase;
    color: ${({ theme }) => theme.colors.white};
    opacity: 1;
    text-shadow: 0px 0px 3px #e13942, 0px 0px 12px #ff242c;
  }
`

export const StyledCollaborationLoader = styled(CollaborationLoader)`
  margin: 12px 0 6px;
  max-width: 100%;
  height: auto;
  padding: 0 0 12px;
  border-bottom: 1px solid rgba(256, 256, 256, 0.5);

  rect {
    opacity: 0;
  }
`

export const ProgressContainer = styled.div`
  display: flex;
  justify-content: space-between;
  alignt-items: center;
`

export const ContentContainer = styled.div`
  grid-row: 1/-1;
  grid-column: 1/-1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1266px;
  margin: 0 auto;
  width: 100%;
  opacity: 0;
  visibility: hidden;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    max-width: 900px;
  }
`

export const CollaborationContentContainer = styled.div`
  max-width: 670px;
  opacity: 0;
  visibility: hidden;

  ${H2} span {
    font-weight: 700;
  }

  ${Body2}:first-of-type {
    margin-bottom: 1em;
  }

  ${Body2}:last-of-type {
    margin-bottom: 32px;
  }

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    width: 56%;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    width: 78%;
  }
`

export const Frame = styled.div`
  display: flex;
  align-items: center;

  &.collaboration-frame-left {
    transform: translateX(130%);
  }

  &.collaboration-frame-right {
    transform: translateX(-130%) rotateY(180deg);
  }
`

export const FrameOutter = styled(FrameOutterImage)`
  max-width: 100%;
  height: auto;

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    display: none;
  }
`

export const FrameInner = styled(FrameInnerImage)`
  max-width: 100%;
  height: auto;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    display: none;
  }
`

export const FrameInnerMedium = styled(FrameInnerMediumImage)`
  display: none;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    display: block;
    max-width: 100%;
    height: auto;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    display: none;
  }
`

export const FrameInnerMobile = styled(FrameInnerMobileImage)`
  display: none;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    display: block;
    max-width: 100%;
    height: auto;
  }
`
