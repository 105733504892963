// import gsap from 'gsap'
import React, {
  FC,
  // useEffect, useRef
} from 'react'
import { StyledMain } from './Main.components'

const Main: FC = ({ children }) => {
  // const backgroundRef = useRef(null)

  // useEffect(() => {
  //   gsap.to(backgroundRef.current, {
  //     backgroundPosition: `50% 500px`,

  //     scrollTrigger: {
  //       trigger: backgroundRef.current,
  //       start: 'top top',
  //       scrub: true,
  //     },
  //   })
  // }, [])

  return <StyledMain>{children}</StyledMain>
}

export default Main
