import React, { FC, useEffect, useState } from 'react'
import { Body2 } from '../Typography/Typography.components'

interface CursorPositionProps {
  className: string
}

const CursorPosition: FC<CursorPositionProps> = props => {
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 })

  const handlePointerMove = e => {
    const x = Math.floor(e.clientX)
    const y = Math.floor(e.clientY)

    setMousePosition({ x, y })
  }

  useEffect(() => {
    window.addEventListener('pointermove', e => handlePointerMove(e))

    return window.removeEventListener('pointermove', e => handlePointerMove(e))
  }, [])

  return (
    <Body2 className={props.className}>
      X: {mousePosition.x} Y: {mousePosition.y}
    </Body2>
  )
}

export default CursorPosition
