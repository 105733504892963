import * as THREE from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import React, { useEffect, useMemo, useRef } from 'react'
import { particlesCloudVertex, particlesCloudFragment } from './shaders'
import { isClientSide } from '../../utils/helpers'

const ParticlesCloudShaderMaterial = {
  uniforms: {
    uTime: { value: 0 },
    uPixelRatio: {
      value: Math.min(isClientSide() && window.devicePixelRatio, 2),
    },
  },
  vertexShader: particlesCloudVertex,
  fragmentShader: particlesCloudFragment,
}

const SecondarySpheres = () => {
  const particlesCloudShaderMaterial = useRef(null)
  const pointsCloudRef = useRef(null)

  const { size } = useThree()

  const [pointsOffset, particlesCloudVertices, randomSizes] = useMemo(() => {
    const offset = []
    const particlesCloudPoints = []
    const sizes = []

    const phi = Math.PI * (3 - Math.sqrt(5))

    const particlesCloudNum = 3000

    for (let i = 0; i < particlesCloudNum; i++) {
      const y = 1 - (i / (particlesCloudNum - 1)) * 2

      const radius = Math.sqrt(1 - y * y)
      const theta = phi * i

      const x = Math.cos(theta) * radius
      const z = Math.sin(theta) * radius

      const randomOffset = Math.random()
      const randomSize = Math.random()

      particlesCloudPoints.push(x, y, z)
      offset.push(randomOffset)
      sizes.push(randomSize)
    }

    const pointsOffset = new Float32Array(offset)
    const particlesCloudVertices = new Float32Array(particlesCloudPoints)
    const randomSizes = new Float32Array(sizes)

    return [pointsOffset, particlesCloudVertices, randomSizes]
  }, [])

  const handleResize = () => {
    particlesCloudShaderMaterial.current.uniforms.uPixelRatio.value = Math.min(
      window.devicePixelRatio,
      2,
    )
  }

  useEffect(() => {
    window.addEventListener('resize', handleResize)

    return window.removeEventListener('resize', handleResize)
  }, [])

  useFrame((_, delta) => {
    particlesCloudShaderMaterial.current.uniforms.uTime.value += delta * 3
  })

  return (
    <points
      frustumCulled={false}
      scale={700}
      ref={pointsCloudRef}
      position={[size.width / 3, 0, 0]}
    >
      <bufferGeometry onUpdate={self => self.computeVertexNormals()}>
        <bufferAttribute
          attachObject={['attributes', 'position']}
          count={particlesCloudVertices.length / 3}
          array={particlesCloudVertices}
          itemSize={3}
        />
        <bufferAttribute
          attachObject={['attributes', 'offset']}
          count={pointsOffset.length}
          array={pointsOffset}
          itemSize={1}
        />
        <bufferAttribute
          attachObject={['attributes', 'aRandomSizes']}
          count={randomSizes.length}
          array={randomSizes}
          itemSize={1}
        />
      </bufferGeometry>
      <shaderMaterial
        ref={particlesCloudShaderMaterial}
        attach="material"
        args={[ParticlesCloudShaderMaterial]}
        transparent={true}
        depthTest={false}
        side={THREE.FrontSide}
      />
    </points>
  )
}

export default SecondarySpheres
