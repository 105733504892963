import styled from 'styled-components'
import { Body2, H1, Subtitle1 } from '../Typography/Typography.components'
import HeroBoxOutline from '../../assets/images/inline/hero_box.svg'
import { Wrapper } from '../Shared/Shared.components'
import PlusVector from '../../assets/images/inline/plus.svg'
import HeroLoaderVector from '../../assets/images/inline/dashes.svg'
import { MEDIA_QUERIES, REM_VALUES } from '../../utils/constants'

export const StyledHero = styled(Wrapper)`
  min-height: calc(100vh - 66px);
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    min-height: 750px;
    align-items: flex-end;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    min-height: 710px;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    min-height: 588px;
  }
`

export const HeroBox = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-left: 86px;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    margin-left: 20px;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    margin-left: 0px;
  }
`

export const TextBox = styled.div`
  padding: 40px 110px 40px 64px;
  position: relative;
  max-width: 880px;
  transform-origin: 'left';
  transform: scale(0);

  ${H1} {
    margin-bottom: 16px;

    span {
      font-weight: 700;
    }
  }

  ${Body2} {
    margin-bottom: 0;
  }

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    padding: 40px 58px 40px 40px;
    max-width: 630px;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    padding: 32px 77px 32px 32px;
    max-width: 460px;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding: 16px 22px 22px 16px;
    max-width: 370px;
  }
`

export const TextBoxOutline = styled(HeroBoxOutline)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`

export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-right: 20%;
  padding: 0.5em 0;
  position: relative;

  ${Body2} {
    margin-bottom: 0;
    margin-right: 3em;
    opacity: 0;
  }
`

export const HeroUnderline = styled.div`
  position: absolute;
  height: 1px;
  background: ${({ theme }) => theme.colors.white};
  left: 75%;
  bottom: -2px;
  width: 0;

  span {
    position: absolute;
    display: block;
    width: 0;
    height: 1px;
    background: ${({ theme }) => theme.colors.white};
    left: 100%;
    bottom: 0;
    transform: rotateZ(-34deg);
    transform-origin: left;
  }

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    left: 62%;

    span {
      transform: rotateZ(-44deg);
    }
  }
`

export const HeroLoader = styled(HeroLoaderVector)`
  path {
    opacity: 0;
  }
`

export const Plus = styled(PlusVector)`
  position: absolute;

  &.plus-one {
    top: 0;
    right: 0;
    transform: translate(100%, -100%);

    @media (max-width: ${MEDIA_QUERIES.mobile}px) {
      transform: scale(0.5) translate(150%, -150%);
    }
  }

  &.plus-two {
    bottom: 0;
    left: 0;
    transform: translate(-100%, 100%);

    @media (max-width: ${MEDIA_QUERIES.mobile}px) {
      transform: scale(0.5) translate(-150%, 150%);
    }
  }
`

export const Socials = styled.div`
  position: absolute;
  right: 2em;
  top: 40%;
  transform: translateY(-50%);
  text-align: right;

  ${Subtitle1} {
    opacity: 0;
    display: block;
    color: ${({ theme }) => theme.colors.white};
    text-decoration: none;
    font-weight: 400;
    font-size: ${REM_VALUES[16]};
    line-height: ${REM_VALUES[20]};
    margin: 16px 0;
  }

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    top: 35%;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    top: 27%;
  }
`

export const SocialsUnderline = styled.div`
  position: absolute;
  width: 1px;
  background: ${({ theme }) => theme.colors.white};
  left: 100%;
  bottom: 0;
  transform: translateY(100%);
  height: 0;

  span {
    position: absolute;
    display: block;
    content: '';
    width: 1px;
    height: 0;
    background: ${({ theme }) => theme.colors.white};
    left: 0;
    transform: translateY(100%) rotateZ(50deg);
    bottom: 0;
    transform-origin: top;
  }

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    bottom: 110%;
    transform: translateY(0);

    span {
      bottom: 100%;
      transform: translateY(100%) rotateZ(120deg);
    }
  }
`
