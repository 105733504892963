import glsl from 'babel-plugin-glsl/macro'

export const vertexShader = glsl`
  uniform float uPixelRatio;
  varying vec3 pos;
  varying vec2 vUv;
  attribute float aRandomSizes;
  varying float vRandom;
  varying float vShine;
  uniform float uTime;

  //	Classic Perlin 3D Noise 
    //	by Stefan Gustavson
    //
    vec4 permute(vec4 x){return mod(((x*34.0)+1.0)*x, 289.0);}
    vec4 taylorInvSqrt(vec4 r){return 1.79284291400159 - 0.85373472095314 * r;}
    vec3 fade(vec3 t) {return t*t*t*(t*(t*6.0-15.0)+10.0);}

    float cnoise(vec3 P){
      vec3 Pi0 = floor(P); // Integer part for indexing
      vec3 Pi1 = Pi0 + vec3(1.0); // Integer part + 1
      Pi0 = mod(Pi0, 289.0);
      Pi1 = mod(Pi1, 289.0);
      vec3 Pf0 = fract(P); // Fractional part for interpolation
      vec3 Pf1 = Pf0 - vec3(1.0); // Fractional part - 1.0
      vec4 ix = vec4(Pi0.x, Pi1.x, Pi0.x, Pi1.x);
      vec4 iy = vec4(Pi0.yy, Pi1.yy);
      vec4 iz0 = Pi0.zzzz;
      vec4 iz1 = Pi1.zzzz;

      vec4 ixy = permute(permute(ix) + iy);
      vec4 ixy0 = permute(ixy + iz0);
      vec4 ixy1 = permute(ixy + iz1);

      vec4 gx0 = ixy0 / 7.0;
      vec4 gy0 = fract(floor(gx0) / 7.0) - 0.5;
      gx0 = fract(gx0);
      vec4 gz0 = vec4(0.5) - abs(gx0) - abs(gy0);
      vec4 sz0 = step(gz0, vec4(0.0));
      gx0 -= sz0 * (step(0.0, gx0) - 0.5);
      gy0 -= sz0 * (step(0.0, gy0) - 0.5);

      vec4 gx1 = ixy1 / 7.0;
      vec4 gy1 = fract(floor(gx1) / 7.0) - 0.5;
      gx1 = fract(gx1);
      vec4 gz1 = vec4(0.5) - abs(gx1) - abs(gy1);
      vec4 sz1 = step(gz1, vec4(0.0));
      gx1 -= sz1 * (step(0.0, gx1) - 0.5);
      gy1 -= sz1 * (step(0.0, gy1) - 0.5);

      vec3 g000 = vec3(gx0.x,gy0.x,gz0.x);
      vec3 g100 = vec3(gx0.y,gy0.y,gz0.y);
      vec3 g010 = vec3(gx0.z,gy0.z,gz0.z);
      vec3 g110 = vec3(gx0.w,gy0.w,gz0.w);
      vec3 g001 = vec3(gx1.x,gy1.x,gz1.x);
      vec3 g101 = vec3(gx1.y,gy1.y,gz1.y);
      vec3 g011 = vec3(gx1.z,gy1.z,gz1.z);
      vec3 g111 = vec3(gx1.w,gy1.w,gz1.w);

      vec4 norm0 = taylorInvSqrt(vec4(dot(g000, g000), dot(g010, g010), dot(g100, g100), dot(g110, g110)));
      g000 *= norm0.x;
      g010 *= norm0.y;
      g100 *= norm0.z;
      g110 *= norm0.w;
      vec4 norm1 = taylorInvSqrt(vec4(dot(g001, g001), dot(g011, g011), dot(g101, g101), dot(g111, g111)));
      g001 *= norm1.x;
      g011 *= norm1.y;
      g101 *= norm1.z;
      g111 *= norm1.w;

      float n000 = dot(g000, Pf0);
      float n100 = dot(g100, vec3(Pf1.x, Pf0.yz));
      float n010 = dot(g010, vec3(Pf0.x, Pf1.y, Pf0.z));
      float n110 = dot(g110, vec3(Pf1.xy, Pf0.z));
      float n001 = dot(g001, vec3(Pf0.xy, Pf1.z));
      float n101 = dot(g101, vec3(Pf1.x, Pf0.y, Pf1.z));
      float n011 = dot(g011, vec3(Pf0.x, Pf1.yz));
      float n111 = dot(g111, Pf1);

      vec3 fade_xyz = fade(Pf0);
      vec4 n_z = mix(vec4(n000, n100, n010, n110), vec4(n001, n101, n011, n111), fade_xyz.z);
      vec2 n_yz = mix(n_z.xy, n_z.zw, fade_xyz.y);
      float n_xyz = mix(n_yz.x, n_yz.y, fade_xyz.x); 
      return 2.2 * n_xyz;
    }

  void main() {
    vec4 modelPosition = modelMatrix * vec4(position, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;
    
    pos = position;
    vUv = uv;
    vRandom = aRandomSizes;

    gl_PointSize = 0.1 + 8000.0 * uPixelRatio * aRandomSizes;
    gl_PointSize *= (1.0 / - viewPosition.z);
    gl_Position = projectionPosition;
  }
`

export const fragmentShader = glsl`
  varying vec3 pos;
  uniform sampler2D uTexture;
  varying vec2 vUv;
  varying float vRandom;
  varying float vShine;

  void main() {
    vec3 color1 = vec3(0.27, 0.46, 0.63);
    vec3 color2 = vec3(0.19, 0.35, 0.47);
    vec3 color3 = vec3(0.23, 0.32, 0.39);

    vec3 randomColor = color1;

    if (vRandom > 0.33 && vRandom < 0.66) {
      randomColor = color2;
    }
    if(vRandom > 0.66 && vRandom < 1.) {
      randomColor = color3;
    }

    vec4 color = texture2D(uTexture, vUv);

    if (color.r > 0.3 && color.g > 0.3 && color.b > 0.3) {
      discard;
    }

    vec3 firstColor = mix(randomColor, color.rgb, color.r);

    float alpha = 1. - smoothstep(-0.3, 0.5, length(gl_PointCoord -  vec2(0.5)));

    gl_FragColor = vec4(firstColor.rgb, alpha);
  }
`

export const particlesCloudVertex = glsl`
  uniform float uTime;
  uniform float uPixelRatio;
  attribute float offset;
  attribute float aRandomSizes;

  void main() {

    vec3 newposition = position;

    newposition *= 0.60 + ((offset + sin(uTime * offset)) / 50. );

    vec4 modelPosition = modelMatrix * vec4(newposition, 1.0);
    vec4 viewPosition = viewMatrix * modelPosition;
    vec4 projectionPosition = projectionMatrix * viewPosition;
    
    gl_PointSize = 6000.0 * uPixelRatio * aRandomSizes;
    gl_PointSize *= (1.0 / - viewPosition.z);
    gl_Position = projectionPosition;

  }
`

export const particlesCloudFragment = glsl`
  uniform float uTime;

  void main() {

    float distanceToCenter = distance(gl_PointCoord, vec2(0.5));
    float strength = 0.06 / distanceToCenter - 0.1;

    vec3 color = mix(vec3(1., 1., 1.), vec3(1., 0., 0.), distanceToCenter * 2.);
    
    gl_FragColor = vec4( color, strength );

  }
`

export const videoVertex = glsl`
  uniform float uTime;
  uniform float uPixelRatio;
  uniform float uIntensity;
  varying vec2 vUv;
  varying vec3 pos;

  void main() {
    vec3 newposition = position;

    newposition.z += 10. * sin(position.x * 20. + uTime * 2. * uIntensity) * uIntensity;
    newposition.z += 10. * sin(position.y * 20. + uTime * 2. * uIntensity) * uIntensity;

    vUv = uv;
    pos = newposition;
    
    gl_Position = projectionMatrix * modelViewMatrix * vec4(newposition, 1.);
  }
`

export const videoFragment = glsl`
  uniform float uTime;
  uniform sampler2D uCurrentVideoTexture;
  uniform sampler2D uNextVideoTexture;
  uniform float uProgress;
  varying vec2 vUv;
  varying vec3 pos;

  void main() {

    vec3 fragPos = pos;
    fragPos.xy += 0.5;

    vec4 currVideo = texture2D(uCurrentVideoTexture, fragPos.xy);
    vec4 nextVideo = texture2D(uNextVideoTexture, fragPos.xy);

    vec4 video = mix(currVideo, nextVideo, uProgress);

    float v = 1. - abs(fragPos.x - 0.5) * 1.8;
    float h = 1. - abs(fragPos.y - 0.5) * 1.8;

    float vertAlpha = smoothstep(0., 0.5, v);
    float horAlpha = smoothstep(0., 0.5, h);

    float alpha = vertAlpha * horAlpha;

    video.a = alpha;
    gl_FragColor = video;

  }
`
