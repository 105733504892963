import { useFrame } from '@react-three/fiber'
import React, { FC, useEffect, useLayoutEffect, useRef } from 'react'
import { videoVertex, videoFragment } from './shaders'
import { isClientSide } from '../../utils/helpers'
import { VideoTexture } from 'three'
import gsap from 'gsap'
import { useVideosLoad } from '../../utils/hooks/useVideosLoad'

interface VideoProps {
  currentVideo: number
}

const PlaneShaderMaterial = {
  uniforms: {
    uPixelRatio: {
      value: Math.min(isClientSide() && window.devicePixelRatio, 2),
    },
    uTime: { value: 0 },
    uCurrentVideoTexture: { value: null },
    uNextVideoTexture: { value: null },
    uIntensity: { value: 1 },
    uProgress: { value: 0 },
  },
  vertexShader: videoVertex,
  fragmentShader: videoFragment,
}

const Videos: FC<VideoProps> = ({ currentVideo }) => {
  const videosLoaded = useVideosLoad()

  const dimensions = useRef(null)
  const domVideos = useRef(null)
  const shaderMaterial = useRef(null)
  const planeMeshRef = useRef(null)
  const videoTextures = useRef([])
  const firstRender = useRef(true)

  const handlePointerMove = e => {
    // const x =
    //   e.pageX - dimensions.current.meshX - dimensions.current.meshWidth / 2
    // const y = e.pageY - window.scrollY
    // gsap.to(planeMeshRef.current.rotation, {
    //   y: x * 0.0004,
    //   x: y * 0.0003,
    //   duration: 2,
    // })
    // gsap.to(planeMeshRef.current.position, {
    //   y: () => '+=' + y * 0.04,
    //   x: () => '+=' + x * 0.03,
    //   duration: 1,
    // })
  }

  const getDimensions = () => {
    const bounds = domVideos.current[0].getBoundingClientRect()

    const height = isClientSide() && window.innerHeight
    const width = isClientSide() && window.innerWidth

    dimensions.current = {
      windowHeight: height,
      windowWidth: width,
      meshX: bounds.left + bounds.width / 2 - width / 2,
      meshY: -window.scrollY - bounds.top + height / 2 - bounds.height / 2,
      meshWidth: bounds.width,
      meshHeight: bounds.height,
      meshScaleX: bounds.width,
      meshScaleY: bounds.height,
    }
  }

  const setMeshDimensions = () => {
    planeMeshRef.current.position.x = dimensions.current.meshX
    planeMeshRef.current.position.y = dimensions.current.meshY

    planeMeshRef.current.scale.set(
      dimensions.current.meshScaleX,
      dimensions.current.meshScaleY,
      1,
    )
  }

  const handleResize = () => {
    getDimensions()
    setMeshDimensions()
  }

  useLayoutEffect(() => {
    if (videosLoaded) {
      domVideos.current = document.getElementsByTagName('video')

      getDimensions()
      setMeshDimensions()

      Array.from(domVideos.current).forEach(video => {
        videoTextures.current.push(new VideoTexture(video as HTMLVideoElement))
      })

      shaderMaterial.current.uniforms.uCurrentVideoTexture.value =
        videoTextures.current[currentVideo]

      window.addEventListener('resize', handleResize)
      window.addEventListener('pointermove', handlePointerMove)

      return () => {
        window.removeEventListener('resize', handleResize)
        window.removeEventListener('pointermove', handlePointerMove)
      }
    }
  }, [videosLoaded])

  useEffect(() => {
    if (videosLoaded) {
      shaderMaterial.current.uniforms.uNextVideoTexture.value =
        videoTextures.current[currentVideo]

      if (!firstRender.current) {
        gsap.timeline().to(shaderMaterial.current.uniforms.uProgress, {
          value: 1,
          duration: 0.2,
          onComplete: () => {
            shaderMaterial.current.uniforms.uCurrentVideoTexture.value =
              videoTextures.current[currentVideo]
            shaderMaterial.current.uniforms.uProgress.value = 0
          },
        })
      }

      firstRender.current = false
    }
  }, [videosLoaded, currentVideo])

  useFrame((_, delta) => {
    if (videosLoaded) {
      shaderMaterial.current.uniforms.uTime.value += delta
    }
  })

  return videosLoaded ? (
    <mesh ref={planeMeshRef}>
      <planeGeometry args={[1, 1, 20, 20]}></planeGeometry>
      <shaderMaterial
        ref={shaderMaterial}
        attach="material"
        args={[PlaneShaderMaterial]}
        transparent={true}
      />
    </mesh>
  ) : null
}

export default Videos
