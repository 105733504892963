import React, { FC, useContext, useEffect, useRef } from 'react'
import gsap from 'gsap'

import { MyContext } from '../../utils/Context'
import { CTAButton, Wrapper } from '../Shared/Shared.components'
import { Body2, H2 } from '../Typography/Typography.components'
import {
  AboutContainer,
  AboutUsCircles,
  AboutUsDashes,
  AboutUsDashesStraight,
  AboutUsWrapper,
  ButtonContainer,
  Plus,
  TextBoxOutline,
  TextBoxOutlineMedium,
  TextBoxOutlineMobile,
} from './About.components'

import PolygonImage from '../../assets/images/inline/polygon.svg'

const About: FC = () => {
  const context = useContext(MyContext)
  const aboutUsRef = useRef(null)

  useEffect(() => {
    const q = gsap.utils.selector(aboutUsRef)

    gsap
      .timeline({
        scrollTrigger: {
          trigger: aboutUsRef.current,
          start: '-100px 20%',
        },
      })
      .to(q('.about-us-container'), {
        scale: 1,
        duration: 0.3,
        transformOrigin: '100% 100%',
        ease: 'cubic-bezier(0.4, 0.0, 0.2, 1);',
      })
  }, [])

  return (
    <Wrapper>
      <AboutUsWrapper ref={aboutUsRef} className="section-2">
        <AboutUsCircles />
        <AboutContainer className="about-us-container">
          <AboutUsDashesStraight />
          <H2>About us</H2>
          <Body2>
            <span>ML Innovation Hub</span> is the new member of the Medical
            Logistics family! Our team has an immense amount of valuable
            experience in the medical transportation industry and we're always
            on the lookout for new developments. This is why ML Innovation Hub
            was born, as a place to disrupt the industry and create a better
            future together. Come talk to us!
          </Body2>
          <ButtonContainer>
            <CTAButton
              className="cursor-reactive"
              onClick={() => context.handleModal()}
            >
              Talk to us
            </CTAButton>
            <AboutUsDashes />
            <PolygonImage />
          </ButtonContainer>
          <TextBoxOutline />
          <TextBoxOutlineMedium />
          <TextBoxOutlineMobile />
          <Plus />
        </AboutContainer>
      </AboutUsWrapper>
    </Wrapper>
  )
}

export default About
