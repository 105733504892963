import gsap from 'gsap'
import React, { FC, useLayoutEffect, useRef, useState } from 'react'
import { letterTyping } from '../../utils/gsap/animations'
import { Wrapper } from '../Shared/Shared.components'
import LoadingBar from './LoadingBar'
import {
  Laser,
  LoaderButton,
  LoaderContainer,
  LoaderTitle,
  StyledLoadingScreen,
} from './LoadingScreen.components'
import GlitchedBackground from './GlitchedBackground'

interface LoadingScreenProps {
  handleLoadingAnimationEnd: () => void
}

const LoadingScreen: FC<LoadingScreenProps> = props => {
  const [loadingScreenVisibility, setLoadingScreenVisibility] = useState(true)

  const buttonGlitches = [
    [
      {
        autoAlpha: 0,
        duration: 0.2,
      },
      {
        autoAlpha: 0.3,
        duration: 0.3,
      },
      {
        autoAlpha: 0.15,
        duration: 0.1,
      },
      {
        autoAlpha: 0.5,
        duration: 0.1,
      },
      {
        autoAlpha: 0.4,
        duration: 0.2,
      },
      {
        autoAlpha: 0.8,
        duration: 0.2,
      },
    ],
  ]

  const loadingScreenContainerRef = useRef(null)
  const loaderContainerRef = useRef(null)
  const loaderTimeline = useRef(null)

  useLayoutEffect(() => {
    const q = gsap.utils.selector(loaderContainerRef)

    const loaderTitle =
      loaderContainerRef.current.querySelector('.loader-title')

    loaderTimeline.current = gsap
      .timeline()
      .call(() => letterTyping(loaderTitle))
      .to(q('.loader-bar-background'), { opacity: 0.65, duration: 0.5 }, 1.5)
      .to(q('.loader-bar'), { strokeDashoffset: 0, duration: 1 }, 1.7)
      .to(
        q('.loader-button'),
        {
          keyframes: buttonGlitches[0],
        },
        2.4,
      )

    return () => {
      loaderTimeline?.current?.kill()
    }
  }, [])

  useLayoutEffect(() => {
    let hideLoadingScreenAnimation

    const q = gsap.utils.selector(loaderContainerRef)
    const q1 = gsap.utils.selector(loadingScreenContainerRef)

    if (!loadingScreenVisibility) {
      loaderTimeline.current.kill()
      hideLoadingScreenAnimation = gsap
        .timeline()
        .to(
          [
            q('.loader-title'),
            q('.loader-bar-background'),
            q('.loader-bar'),
            q('.loader-button'),
          ],
          {
            autoAlpha: 0,
            duration: 0.3,
          },
        )
        .to(q1('feTurbulence'), {
          attr: {
            baseFrequency: 0.04,
          },
          repeat: -1,
          duration: 0.1,
          yoyo: true,
        })
        .to(q1('.laser'), {
          width: '100%',
          duration: 0.3,
        })
        .to(loadingScreenContainerRef.current, {
          height: 0,
          duration: 1,
          delay: 0.5,
          onComplete: () => props.handleLoadingAnimationEnd(),
        })
    }

    return () => {
      hideLoadingScreenAnimation?.kill()
    }
  }, [loadingScreenVisibility])

  return (
    <StyledLoadingScreen ref={loadingScreenContainerRef}>
      <GlitchedBackground />
      <Wrapper>
        <LoaderContainer ref={loaderContainerRef}>
          <LoaderTitle className="loader-title">
            Welcome to medical logistics innovation hub
          </LoaderTitle>
          <LoadingBar />
          <LoaderButton
            onClick={() => setLoadingScreenVisibility(false)}
            className="cursor-reactive loader-button"
          >
            Start
          </LoaderButton>
        </LoaderContainer>
      </Wrapper>
      <Laser className="laser" />
      <svg display="none">
        <defs>
          <filter id="laserFilter">
            <feTurbulence
              type="fractalNoise"
              baseFrequency="0.07"
              numOctaves="1"
              result="warp"
            ></feTurbulence>
            <feOffset dx="-50" result="warpOffset"></feOffset>
            <feDisplacementMap
              xChannelSelector="R"
              yChannelSelector="G"
              scale="20"
              in="SourceGraphic"
              in2="warpOffset"
            ></feDisplacementMap>
          </filter>
        </defs>
      </svg>
    </StyledLoadingScreen>
  )
}

export default LoadingScreen
