import styled from 'styled-components'
import { Body1 } from '../Typography/Typography.components'
import ImgOne from '../../assets/images/loading_screen_background.png'
import { REM_VALUES } from '../../utils/constants'

export const StyledLoadingScreen = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 12;
  background-color: ${({ theme }) => theme.colors.bodyBackground};

  ${Body1} {
    text-transform: uppercase;
    font-size: ${REM_VALUES[20]};
  }
`

export const LoaderContainer = styled.div`
  display: flex;
  position: relative;
  margin-bottom: 10%;

  svg {
    max-width: 100%;
    height: auto;

    path.loader-bar {
      stroke-dasharray: 144%;
      stroke-dashoffset: 144%;
    }
  }

  @media (max-width: 600px) {
    flex-direction: column;
    align-items: center;
  }
`

export const LoaderTitle = styled(Body1)`
  position: absolute;
  left: 0;
  bottom: 10px;
  max-width: 220px;
  opacity: 0;
  visibility: hidden;

  @media (max-width: 1024px) {
    bottom: 70%;
  }

  @media (max-width: 600px) {
    bottom: 100%;
  }
`

export const LoaderButton = styled(Body1)`
  color: ${({ theme }) => theme.colors.blue};
	display: inline-block;
	align-self: flex-start;
  margin-left: 16px;
  margin-bottom: 0;
  margin-top: -2px;
  opacity: 0;
  visibility: hidden;
	transform-origin: '50% 50%',
  transition: all 0.3s ease-in-out;

  &:hover {
    transform: scale(0.95);
		transition: all 0.3s ease-in-out;
  }

  @media (max-width: 600px) {
    margin-top: 30px;
    align-self: center;
  }
`

export const StyledGlitchedBackground = styled.div`
  display: block;
  max-width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
`

export const GlitchedImage = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: url(${ImgOne});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  opacity: 0.2;
`

export const Laser = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: 0;
  height: 3px;
  background: ${({ theme }) => theme.colors.red.ctaLight};
  border: 1px solid ${({ theme }) => theme.colors.red.ctaLight};
  box-shadow: 0px 0px 3px #e13942, 0px 0px 8px #ff242c,
    inset 0px 0px 5px #ff2a1c;
  filter: url('#laserFilter');
  overflow: visible;
`
