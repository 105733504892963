import styled from 'styled-components'
import { Wrapper } from '../Shared/Shared.components'
import Logo from '../../assets/images/inline/footer_logo.svg'
import LogoSmall from '../../assets/images/inline/footer_logo_small.svg'
import { Button, Caption, H4 } from '../Typography/Typography.components'
import { MEDIA_QUERIES, REM_VALUES } from '../../utils/constants'

export const StyledWrapper = styled(Wrapper)`
  position: relative;
  z-index: 3;
`

export const FooterLogo = styled(Logo)`
  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    display: none;
  }
`

export const FooterLogoSmall = styled(LogoSmall)`
  display: none;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    display: block;
  }
`

export const FooterContainer = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  padding: 328px 188px 115px 102px;

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    padding: 0;
    padding-top: 200px;
    padding-bottom: 250px;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    grid-template-columns: 100%;
    grid-template-rows: auto auto;
  }
`

export const MainFooterContainer = styled.div`
  display: flex;

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    grid-row: 2/3;
  }
`

export const FooterInfo = styled.div`
  flex-basis: 60%;

  ${Button} {
    text-transform: initial;

    &:first-of-type {
      margin-top: 52px;
    }

    &:last-of-type {
      margin-bottom: 15px;
    }
  }

  > *:not(:nth-child(1), :nth-child(2)) {
    margin-left: 89px;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    > *:not(:nth-child(1), :nth-child(2)) {
      margin-left: 70px;
    }
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    ${Button} {
      &:first-of-type {
        margin-top: 26px;
      }
    }

    > *:not(:nth-child(1), :nth-child(2)) {
      margin-left: 0;
    }
  }
`

export const Socials = styled.div`
  display: flex;

  a:hover .social-background {
    fill: rgba(143, 255, 249, 0.3);
    stroke: #16d6e2;
  }

  > * + * {
    margin-left: 30px;
  }
`

export const FooterMenu = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 7.7em;
  flex-basis: 40%;

  > * {
    margin: 0;
  }

  > * + * {
    margin-top: 22px;
  }

  @media (max-width: ${MEDIA_QUERIES.tablet}px) {
    padding-left: 40px;
    padding-top: 140px;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    padding-top: 118px;
  }

  @media (max-width: ${MEDIA_QUERIES.mobile}px) {
    padding-top: 92px;
    padding-left: 12px;
  }
`

export const FooterForm = styled.div`
  padding-top: 1em;

  ${H4} {
    text-shadow: 0px 0px 3px #085892, 0px 0px 8px #16d6e2;
  }

  @media (max-width: ${MEDIA_QUERIES.smallTablet}px) {
    grid-row: 1/2;
    margin-bottom: 90px;
    max-width: 409px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
`

export const FormBody = styled.form`
  margin: 0;

  .error-message {
    margin: 0;
    margin-bottom: 16px;
    color: ${({ theme }) => theme.colors.red.ctaLight};
  }
`

export const Author = styled.p`
  font-size: ${REM_VALUES[12]};
  margin-top: 20px;

  a {
    color: inherit;
    font-weight: 700;
  }
`
