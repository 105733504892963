import gsap from 'gsap'
import { transformWordToSpans } from '../helpers/transformWordToSpans'

export const letterTyping = (elem: HTMLElement, stagger: number = 0.03) => {
  transformWordToSpans(elem)

  gsap.set(elem.getElementsByTagName('span'), { autoAlpha: 0 })
  gsap.set(elem, { autoAlpha: 1 })
  gsap.set(elem.getElementsByTagName('span'), {
    autoAlpha: 1,
    stagger,
    delay: 0.5,
  })
}
