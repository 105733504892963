import { Canvas } from '@react-three/fiber'
import styled from 'styled-components'

export const StyledCanvas = styled(Canvas)`
  &&& {
    position: fixed !important;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 0;
  }
`
