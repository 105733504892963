import React, { FC, Suspense, useContext } from 'react'
import { StyledCanvas } from './ThreeScene.components'

import MainSphere from './MainSphere'
import SecondarySpheres from './SecondarySpheres'
import SphereCamera from './SphereCamera'
import Videos from './Videos'
import { MyContext } from '../../utils/Context'

interface ThreeSceneProps {
  animateHero: boolean
}

const ThreeScene: FC<ThreeSceneProps> = () => {
  const context = useContext(MyContext)

  return (
    <StyledCanvas>
      <Suspense fallback={null}>
        <SphereCamera />
        <MainSphere />
        <SecondarySpheres />
        <Videos currentVideo={context.currentService} />
      </Suspense>
    </StyledCanvas>
  )
}

export default ThreeScene
