import gsap from 'gsap'
import React, { FC, useContext, useEffect, useRef, useState } from 'react'
import { MyContext } from '../../utils/Context'
import { handleNavigationItemClick } from '../../utils/helpers/navigate'
import { Button } from '../Typography/Typography.components'
import HamburgerMenu from './HamburgerMenu'
import {
  HamburgerButton,
  Nav,
  NavList,
  NavUnderline,
  StyledCTAButton,
  StyledHeader,
  StyledWrapper,
} from './Header.components'

interface HeaderProps {
  animateHero: boolean
}

const Header: FC<HeaderProps> = props => {
  const headerTimeline = useRef(null)
  const navRef = useRef(null)
  const [menuOpened, setMenuOpened] = useState(false)

  const context = useContext(MyContext)

  useEffect(() => {
    if (props.animateHero) {
      const navLongUnderline = navRef.current.querySelector('.nav-underline')
      const navShortUnderline = navRef.current.querySelector(
        '.nav-underline span',
      )
      const navLinks = navRef.current.querySelectorAll('.desktop-nav-links')
      const navCTAButton = navRef.current.querySelector('.nav-cta-button')

      headerTimeline.current = gsap
        .timeline()
        .to(
          navLinks,
          {
            stagger: 0.2,
            opacity: 1,
          },
          '-=0.5',
        )
        .to(
          navCTAButton,
          {
            opacity: 1,
            duration: 0.3,
          },
          '-=0.5',
        )
        .to(navLongUnderline, {
          width: '100%',
          duration: 0.5,
        })
        .to(navShortUnderline, {
          width: '13%',
          duration: 0.3,
        })
    }

    return () => {
      headerTimeline?.current?.kill()
    }
  }, [props.animateHero])

  const handleMenu = () => setMenuOpened(menuOpened => !menuOpened)

  return (
    <StyledHeader className="section-1">
      <StyledWrapper ref={navRef}>
        <HamburgerButton
          onClick={() => handleMenu()}
          active={menuOpened}
          className="cursor-reactive"
        >
          <div className="hamburger-btn-line line-one"></div>
          <div className="hamburger-btn-line line-two"></div>
          <span className="hamburger-btn-underline line-one">
            <span className="hamburger-btn-underline line-two"></span>
          </span>
        </HamburgerButton>
        <HamburgerMenu active={menuOpened} handleMenu={handleMenu} />
        <Nav>
          <NavList>
            <li
              onClick={() => handleNavigationItemClick('section-1')}
              className="desktop-nav-links cursor-reactive hover-indicator hover-indicator--bottom"
            >
              <Button>Home</Button>
            </li>
            <li
              onClick={() => handleNavigationItemClick('section-2')}
              className="desktop-nav-links cursor-reactive hover-indicator hover-indicator--bottom"
            >
              <Button>About us</Button>
            </li>
            <li
              onClick={() => handleNavigationItemClick('section-3')}
              className="desktop-nav-links cursor-reactive hover-indicator hover-indicator--bottom"
            >
              <Button>Services</Button>
            </li>
            <li
              onClick={() => handleNavigationItemClick('section-4')}
              className="desktop-nav-links cursor-reactive hover-indicator hover-indicator--bottom"
            >
              <Button>Collaboration</Button>
            </li>
            <li
              onClick={() => handleNavigationItemClick('section-5')}
              className="desktop-nav-links cursor-reactive hover-indicator hover-indicator--bottom"
            >
              <Button>Contact</Button>
            </li>
          </NavList>
          <NavUnderline className="nav-underline">
            <span></span>
          </NavUnderline>
        </Nav>
        <StyledCTAButton
          onClick={() => context.handleModal()}
          className="cursor-reactive nav-cta-button"
        >
          Talk to us
        </StyledCTAButton>
      </StyledWrapper>
    </StyledHeader>
  )
}

export default Header
