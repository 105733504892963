import styled from 'styled-components'

import BackgroundImage from '../../assets/images/background.png'
import BackgroundSquaresImage from '../../assets/images/background_squares.png'

export const StyledMain = styled.div`
  width: 100%;
  height: 100%;
  background-image: url(${BackgroundSquaresImage}), url(${BackgroundImage});
  background-repeat: no-repeat, no-repeat;
  background-size: cover, cover;
  background-position: 50% 0, 50% 0;
`
