export const transformWordToSpans = htmlElem => {
  const splittedWord = htmlElem.innerHTML.split('')

  const splittedLettersInSpans = splittedWord.map(letter => {
    if (letter === ' ') return ' '

    return `<span>${letter}</span>`
  })

  const wordWithSpans = splittedLettersInSpans.join('')

  htmlElem.innerHTML = wordWithSpans
}
